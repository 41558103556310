body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'icomoon';
  src:  url(/static/media/icomoon.0056e3cc.eot);
  src:  url(/static/media/icomoon.0056e3cc.eot#iefix) format('embedded-opentype'),
    url(/static/media/icomoon.4f0303aa.ttf) format('truetype'),
    url(/static/media/icomoon.a2710dfd.woff) format('woff'),
    url(/static/media/icomoon.74a6055e.svg#icomoon) format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-logo:before {
  content: "\E900";
}
.icon-cardiovascular:before {
  content: "\E901";
}
.icon-dental:before {
  content: "\E902";
}
.icon-dermatology:before {
  content: "\E903";
}
.icon-endocrine:before {
  content: "\E904";
}
.icon-gastrointestinal:before {
  content: "\E905";
}
.icon-hematologic:before {
  content: "\E906";
}
.icon-immunologic:before {
  content: "\E907";
}
.icon-infectious:before {
  content: "\E908";
}
.icon-musculoskeletal:before {
  content: "\E909";
}
.icon-neurologic:before {
  content: "\E90A";
}
.icon-psychiatric:before {
  content: "\E90B";
}
.icon-renal:before {
  content: "\E90C";
}
.icon-reproductive:before {
  content: "\E90D";
}
.icon-respiratory:before {
  content: "\E90E";
}
.icon-rheumatologic:before {
  content: "\E90F";
}

.react-header {
  height: 50px;
  position: fixed;
  top: 0;
  background-color: white;
  width: 100%;
  font-family: futura-pt, sans-serif;
  border-bottom: 1px solid #e7e7e7;
  z-index: 14;
}

.react-header .header-nav {
  padding: 5px 15px;
}

.react-header .header-nav .left-nav {
  float: left;
  width: 50%;
  z-index: 2;
  position: relative;
}
.react-header .header-nav .left-nav .menu-item {
  display: inline-block;
  margin-left: 35px;
  font-size: 14px;
  cursor: pointer;
}

.react-header .header-nav .right-nav {
  float: right;
  width: 50%;
  text-align: right;
  z-index: 2;
  position: relative;
}
.react-header .header-nav .right-nav .menu-item {
  display: inline-block;
  margin-left: 35px;
  font-size: 14px;
  cursor: pointer;
}
@media (max-width: 576px) {
  .react-header .header-nav .right-nav {
    position: relative;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(30%);
            transform: translateY(30%);
  }
}
@media (max-width: 768px) {
  .react-header .header-nav .left-nav .menu-item {
    display: none;
  }
}

@media (max-width: 900px) {
  .react-header .header-nav {
    padding: 4px 0;
  }
  .react-header .header-nav .right-nav .menu-item {
    display: none;
  }
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.codehealth-font-family {
  font-family: futura-pt, sans-serif;
}
.sm {
  font-size: 12px;
}

.menu-anchor {
  color: #333;
  text-decoration: none;
}
.menu-anchor:hover {
  text-decoration: none;
}
@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

