@font-face {
  font-family: 'icomoon';
  src:  url('./icomoon.eot?oqft1k');
  src:  url('./icomoon.eot?oqft1k#iefix') format('embedded-opentype'),
    url('./icomoon.ttf?oqft1k') format('truetype'),
    url('./icomoon.woff?oqft1k') format('woff'),
    url('./icomoon.svg?oqft1k#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-logo:before {
  content: "\e900";
}
.icon-cardiovascular:before {
  content: "\e901";
}
.icon-dental:before {
  content: "\e902";
}
.icon-dermatology:before {
  content: "\e903";
}
.icon-endocrine:before {
  content: "\e904";
}
.icon-gastrointestinal:before {
  content: "\e905";
}
.icon-hematologic:before {
  content: "\e906";
}
.icon-immunologic:before {
  content: "\e907";
}
.icon-infectious:before {
  content: "\e908";
}
.icon-musculoskeletal:before {
  content: "\e909";
}
.icon-neurologic:before {
  content: "\e90a";
}
.icon-psychiatric:before {
  content: "\e90b";
}
.icon-renal:before {
  content: "\e90c";
}
.icon-reproductive:before {
  content: "\e90d";
}
.icon-respiratory:before {
  content: "\e90e";
}
.icon-rheumatologic:before {
  content: "\e90f";
}
